
<!-- <div class="text-right m-1">
    <button mat-button (click)="clickToDownload()"
        class="bg-primary text-white" mat-button mat-flat-button>
        <mat-icon aria-hidden="false" aria-label="material-icons-filled "
            class="cursor-pointer fs-5">cloud_download
        </mat-icon>
        Download
    </button>
</div> -->

<div class="row">
    <div class="col-md-12">
        <div class="custom-height">
            <div class="custom-table table-responsive" style="max-height: calc(100vh - 220px);">
                <table class="table table-bordered">
                    <thead style="position: sticky; z-index: 1">
                        <tr>
                            <th *ngFor="let field of trackerColumn" style="vertical-align: top;" scope="col" class="text-center">{{field}}</th>
                            <th>
                                Action Plan
                                <!-- <br>
                                (Risk Owner, Action Plan, Due Date) -->
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let risk of riskTrackerList?.records">
                            <td>{{risk?.project}}</td>
                            <td>{{risk?.portfolio}}</td>
                            <td>{{risk?.subportfolio}}</td>
                            <td>{{risk?.program}}</td>
                            <td class="w-25">{{risk?.risk}}</td>
                            <td>{{risk?.risk_domain}}</td>
                            <td>{{risk?.risk_sub_domain}}</td>
                            <td>{{risk?.risk_response}}</td>
                            <td>{{risk?.status}}</td>
                            <td>
                                <div *ngIf="risk?.details?.length > 0">
                                    <ol class="pl-3">
                                        <li *ngFor="let det of risk?.details">
                                            <span *ngIf="det?.owner">{{det?.owner}}</span>
                                            <span *ngIf="det?.action_plan">, {{det?.action_plan}}</span>
                                            <span *ngIf="det?.planned_end_date">, {{det?.planned_end_date}}</span>
                                        </li>
                                    </ol>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <ng-container *ngIf="!riskTrackerList?.records?.length">
                        <div class="noRecords d-flex justify-content-center text-center">
                            <span>
                                No records found.
                            </span>
                        </div>
                    </ng-container>
                </table>
            </div>
            <div class="d-flex  align-items-center justify-content-between" *ngIf="riskTrackerList?.count > 0">
                <div class="p-1">
                    <div
                        class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark">
                        <span class="p-1">Displaying</span>
                        <select class="form-select form-select-sm table-sort-filter"
                            aria-label="Default select example" [(ngModel)]="sortRecords"
                            (ngModelChange)="getRecords(true)">
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                        </select>
                        <span class="p-1">{{riskTrackerList.count}}</span>
                        <span class="p-1">records</span>
                    </div>
                </div>
                <div class="p-1" >
                    <ngb-pagination [pageSize]="sortRecords" [collectionSize]="riskTrackerList?.count" [maxSize]="2"
                        [rotate]="true" [(page)]="CurrentPage" [boundaryLinks]="true"
                        (pageChange)="getRecords(true);">
                    </ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</div>