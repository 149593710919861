import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RiskConvergenceRoutingModule } from './risk-convergence-routing.module';
import { IndexComponent } from './index/index.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChartModule } from 'angular-highcharts';
import { HighchartsChartModule } from 'highcharts-angular';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MaterialmodulesModule } from 'src/app/shared/materialmodules.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { RiskTrackerComponent } from './risk-tracker/risk-tracker.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  declarations: [
    IndexComponent,
    DashboardComponent,
    RiskTrackerComponent
  ],
  imports: [
    CommonModule,
    RiskConvergenceRoutingModule,
    SharedModule,
    ChartModule,
    HighchartsChartModule,
    AngularEditorModule,
    MaterialmodulesModule,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxPermissionsModule,
    PowerBIEmbedModule
  ]
})
export class RiskConvergenceModule { }
