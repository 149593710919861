import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { GetRandomColorPipe } from 'src/app/shared/pipes/get-random-color.pipe';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { RiskConvergenceService } from 'src/app/shared/services/risk-convergence.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormArray, FormGroup, NgForm } from '@angular/forms';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { COMMA, ENTER, I, O } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { DatePipe } from '@angular/common';
import { map, startWith } from 'rxjs/operators';
import { isArray } from 'lodash';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { NgxPermissionsService } from 'ngx-permissions';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { MessageService } from 'src/app/shared/services/message.service';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  providers: [GetRandomColorPipe, DatePipe],
  encapsulation: ViewEncapsulation.Emulated

})
export class IndexComponent implements OnInit {

  createForm: FormArray | any;

  deleted_domains: any = [];
  deleted_subdomains: any = [];
  deleted_risks: any = [];
  loading:any = false;
  staticText: any = (textConfiguration as any).default;

  constructor(private riskService: RiskConvergenceService,
    private datepipe: DatePipe, private randomColor: GetRandomColorPipe,    private projectService: ProjectService,

    public fb: FormBuilder, private toastrService: ToastrService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService, private dialog: MatDialog,
    private permissionsService: NgxPermissionsService,private _formValidator:FormValidatorService
  ) { }

  ngOnInit(): void {
    this.permissionsService.loadPermissions(JSON.parse(localStorage.getItem('permission') || '{}').permissions);
    this.createForm = this.fb.array([]);
    this.getMaster();
    this.messageService.setGnews('Risk Register')
  }

  getMaster(){
    this.spinner.show();
    this.riskService.getRiskMaster().subscribe((res: any) => {
      res.risk_domains.forEach((obj: any) => {
        obj.name = obj.display_name;
        obj.sub_domains.forEach((subdomain:any)=>{
          subdomain.name = subdomain.display_name;
          subdomain.risks.forEach((risk:any) => {
            risk.name = risk.display_name;
          });
        })
        obj.subdomains = obj.sub_domains;
        this.createForm.value.push(obj);
      });

      this.spinner.hide();
    })
  }

  addDomainItems(){
    this.createForm.value.push({
      name: "",
      prefix: "",
      impact: ""
    })

    this.edit(this.createForm.value.length - 1)
  }

  addSubdomainsItems(i: any){
    if(!this.createForm.value[i].subdomains){
      this.createForm.value[i].subdomains = [];
    }
    
    this.createForm.value[i].subdomains.push({
      name: "",
      prefix: "",
      impact: ""
    })

    this.edit(i, this.createForm.value[i].subdomains.length - 1)
  }

  addRisksItems(i: any, j: any){
    if(!this.createForm.value[i].subdomains[j].risks){
      this.createForm.value[i].subdomains[j].risks = [];
    }
   
    this.createForm.value[i].subdomains[j].risks.push({
      name: "",
      prefix: "",
      impact: ""
    })

    this.edit(i, j, this.createForm.value[i].subdomains[j].risks.length - 1)
  }

  removeDomains(i: any){
    if(this.createForm.value[i].id){
      this.deleted_domains.push(this.createForm.value[i].id);
    }
    
    this.createForm.value.splice(i, 1)
  }

  removeSubdomain(i: any, j: any){
    if(this.createForm.value[i].subdomains[j].id){
      this.deleted_subdomains.push(this.createForm.value[i].subdomains[j].id);
    }

    this.createForm.value[i].subdomains.splice(j, 1)
  }

  removeRisks(i: any, j: any, k: any){
    if(this.createForm.value[i].subdomains[j].risks[k].id){
      this.deleted_risks.push(this.createForm.value[i].subdomains[j].risks[k].id);
    }

    this.createForm.value[i].subdomains[j].risks.splice(k, 1)
  }

  saveRisk(){
    let payload = {
      domains: this.createForm.value,
      deleted_domains: this.deleted_domains,
      deleted_subdomains: this.deleted_subdomains,
      deleted_risks: this.deleted_risks
    }
    this.riskService.updateRisk(payload).subscribe((res: any) => {
      this.createForm = this.fb.array([]);
      this.getMaster();
      this.toastrService.success("Risk updated")
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

       getTagObject(id: any){
        return {
          
          entity_id: id,
          entity_type: "risk"
        }
      }


  edit(i: any = undefined, j: any = undefined, k: any = undefined){
    this.createForm.value.forEach((iind: any) => {
      iind.edit = false;
      iind?.subdomains?.forEach((krind: any) => {
        krind.edit = false;
        krind?.risks?.forEach((goind: any) => {
          goind.edit = false;
        });
      });
    });
    if(k != undefined){
      this.createForm.value[i].subdomains[j].risks[k].edit = true;
      this.createForm.value[i].subdomains[j].risks[k].edit_name = this.createForm.value[i].subdomains[j].risks[k].display_name;
    }else if(j != undefined){
      this.createForm.value[i].subdomains[j].edit = true;
      this.createForm.value[i].subdomains[j].edit_name = this.createForm.value[i].subdomains[j].display_name;
    }else{
      this.createForm.value[i].edit = true;
      this.createForm.value[i].edit_name = this.createForm.value[i].display_name;
    }
    
  }

  update(i: any = undefined, j:any = undefined, k:any = undefined){
    this.createForm.value.forEach((iind: any) => {
      iind.edit = false;
      iind?.subdomains?.forEach((krind: any) => {
        krind.edit = false;
        krind?.risks?.forEach((goind: any) => {
          goind.edit = false;
        });
      });
    });
    if(k != undefined){
      this.createForm.value[i].subdomains[j].risks[k].edit = false;
      this.createForm.value[i].subdomains[j].risks[k].name = this.createForm.value[i].subdomains[j].risks[k].edit_name;
    }else if(j != undefined){
      this.createForm.value[i].subdomains[j].edit = false;
      this.createForm.value[i].subdomains[j].name = this.createForm.value[i].subdomains[j].edit_name;
    }else{
      this.createForm.value[i].edit = false;
      this.createForm.value[i].name = this.createForm.value[i].edit_name;
    }
  }

  cancel(i: any, j: any, k: any){
    this.createForm.value.forEach((iind: any) => {
      iind.edit = false;
      iind?.subdomains?.forEach((krind: any) => {
        krind.edit = false;
        krind?.risks?.forEach((goind: any) => {
          goind.edit = false;
        });
      });
    });
  }
}
