import { Component, OnInit } from "@angular/core";
import { Chart } from "angular-highcharts";
import { NgxSpinnerService } from "ngx-spinner";
import { ProgramService } from "src/app/shared/services/program.service";
import { RiskConvergenceService } from "src/app/shared/services/risk-convergence.service";
import * as Highcharts from "highcharts";
import highchartsTreemap from "highcharts/modules/treemap";
highchartsTreemap(Highcharts);
import * as textConfiguration from "src/assets/static-text-configuration.json";
import { NgxPermissionsService } from "ngx-permissions";
import { ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { isArray } from "lodash";
import {
  IReportEmbedConfiguration,
  models,
  Page,
  Report,
  service,
  Embed,
} from "powerbi-client";
import { PowerBiService } from "../../../shared/services/power-bi.service";
import { MatDialog } from "@angular/material/dialog";
import { ProjectService } from "src/app/shared/services/project.service";
import { cloneDeep, forEach } from "lodash";
import { FilterService } from "src/app/shared/services/filter.service";
import { MessageService } from "src/app/shared/services/message.service";
import { ScorecardService } from "src/app/shared/services/scorecard.service";
import { PortfolioDialogComponent } from "../../balancescore/portfolio-dialog/portfolio-dialog.component";
import { PulsesDialogComponent } from "../../delivery-functionality/pulses-dialog/pulses-dialog.component";

declare var require: any;
let Boost = require("highcharts/modules/boost");
let noData = require("highcharts/modules/no-data-to-display");
let More = require("highcharts/highcharts-more");
let threeD = require("highcharts/highcharts-3d");
Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
threeD(Highcharts);
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  encapsulation: ViewEncapsulation.Emulated,
})
export class DashboardComponent implements OnInit {
  currentYear: number | any;
  riskHeatMapData: any[] = [];
  CarouselFilterList: any[] = [];
  projectList: any[] = [];
  ideaList: any[] = [];
  opportunityList: any[] = [];
  projectViewAll: boolean = false;
  ideasViewAll: boolean = false;
  opportunityViewAll: boolean = false;
  initiativeData: any;
  initativeId: any;
  subPortId: any;
  portFolioId: any;
  subPortfilteredOptions: any[] = [];
  projectListData: any[] = [];
  project: any;
  ProjectCount: any;
  staticText: any = (textConfiguration as any).default;
  allRiskWithProjects: any = [];
  searchProjectText: any = [];
  searchText: any = "";
  toggleAllProjects: boolean = true;
  riskDetails: any = [];

  allProjectArrow: any = [];
  allSubProjectArrow: any = [];
  searchProjects: any = [];
  btoa: any = btoa;
  pieChart: any;
  portFolioList: any;
  impactedOKR: any = [];
  applications: any;
  loading: boolean = false;
  context: any;
  reportClass = "report-container";
  selected: any;
  riskArray: any;
  riskArrayHeatMap: any;
  selectedRisks: any;
  projectId: any;
  project_name: any;
  reportModel: IReportEmbedConfiguration = {
    type: "report",
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  };
  insightModel = this.reportModel;
  selectedTab: any = { name: "Risk Heatmap", status: "active", tab: 1 };
  filterobject: any;
  filterObj: any;
  params: any;
  showRisk: boolean = true;
  pulsesList: any;
  positivePulses: any = 0;
  negativePulses: any = 0;

  accordionItemsLeft = [
    { name: "Risk Heatmap", status: "active", tab: 1 },
    { name: "Insights", status: "inactive", tab: 2 },
    { name: "Risk Tracker", status: "inactive", tab: 3 },
  ];

  constructor(
    private RiskConvergenceService: RiskConvergenceService,
    private ProgramService: ProgramService,
    private spinner: NgxSpinnerService,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private PowerBiService: PowerBiService,
    private projectService: ProjectService,
    private dialog: MatDialog,
    private filterService: FilterService,
    private messageService: MessageService,
    private ScorecardService: ScorecardService
  ) {
    this.context = {
      componentParent: this,
    };
  }

  ngOnInit(): void {
    if (this.selectedTab.tab === 1) {
      this.messageService.setGnews("Delivery Risk - Risk Heatmap");
    }
    if (this.selectedTab.tab === 2) {
      this.messageService.setGnews("Delivery Risk - Insights");
    }
    if (this.selectedTab.tab === 3) {
      this.messageService.setGnews("Delivery Risk - Risk Tracker");
    }
    this.permissionsService.loadPermissions(
      JSON.parse(localStorage.getItem("permission") || "{}").permissions
    );
    this.currentYear = new Date().getFullYear();
    this.getRisk();
    this.getPowerBIReportDetails();
    // this.loadPowerBIReports();
    this.filterService.filterSubject.subscribe((res: any) => {
      let awl = false;
      this.filterObj = res;
      Object.keys(this.filterObj).forEach((element: any) => {
        if (this.filterObj[element]?.array?.length > 0) {
          awl = true;
          return;
        }
      });
    });
    // this.getRiskHeatMap();
    // this.filtersFunctions();
    this.getRiskDetails();
    this.getPulse();
  }

  getPulseCounts() {
    this.positivePulses = 0;
    this.negativePulses = 0;
    this.pulsesList.forEach((pulse: any) => {
      if (pulse.is_positive) {
        this.positivePulses++;
      } else {
        this.negativePulses++;
      }
    });
  }

  getPerformanceImpact(pulse: any) {
    let perfImpact = "";
    pulse.performance_impact.forEach((impact: any) => {
      perfImpact += impact.display_name + ", ";
    });
    perfImpact = perfImpact.trimEnd();
    if (perfImpact.endsWith(",")) {
      perfImpact = perfImpact.slice(0, -1);
    }
    return perfImpact;
  }

  getPulseGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 50%, 25%, 90%)";
  }

  getPulse() {
    this.RiskConvergenceService.getRiskPulse().subscribe((res: any) => {
      this.pulsesList = res;
      this.pulsesList.forEach((pulse: any) => {
        pulse["color"] = this.getPulseGMapRandomColor();
        pulse["impact"] = this.getPerformanceImpact(pulse);
      });
      this.getPulseCounts();
    });
  }

  showPulses() {
    const dialog = this.dialog.open(PulsesDialogComponent, {
      width: "80vw",
      data: {
        pulsesList: this.pulsesList,
        isRisk: true,
      },
    });
    dialog.afterClosed().subscribe(() => {
      this.getPulse();
    });
  }

  getRiskDetails() {
    this.ScorecardService.getRiskDetails().subscribe((resp: any) => {
      this.riskDetails = resp;
      this.riskDetails.forEach((risk: any) => {
        risk["color"] = this.getGMapRandomColor();
        risk.portfolio.forEach((portfolio: any) => {
          portfolio["color"] = this.getGMapRandomColor();
        });
      });
      console.log(this.riskDetails);
    });
  }

  getRisk() {
    this.spinner.show();
    this.RiskConvergenceService.getRiskMaster().subscribe(
      (getHeatMap: any) => {
        this.spinner.hide();
        this.riskHeatMapData = getHeatMap.risk_domains;
        let nb = false;

        let len = 0;
        this.riskHeatMapData.forEach((dom: any) => {
          dom.sub_domains.forEach((sub: any) => {
            sub.risks.length > len ? (len = sub.risks.length) : len;
          });
        });

        this.riskHeatMapData.forEach((dom: any) => {
          dom.sub_domains.forEach((sub: any) => {
            if (sub.risks.length < len) {
              for (var array = [], i = sub.risks.length; i < len; i++) {
                sub.risks.push({});
              }
            }

            sub.risks.forEach((goal: any) => {
              if (!nb) {
                this.showInfo(goal.id);
                nb = true;
              }
            });
          });
        });

        // this.getAllProjectList();
        //}
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  getTrucatedName(subdomain: string, name: string) {
    if (!name) {
      return "NA";
    } else {
      if (name.length > subdomain.length) {
        return (
          name.substring(
            0,
            subdomain.length - 1 > 10 ? subdomain.length - 1 : 10
          ) + "..."
        );
      } else {
        return name;
      }
    }
  }

  showInfo(id: any) {
    this.selected = id;
    delete this.portFolioList;
    this.loading = true;

    let queryParam = `risk=${id}`;
    this.RiskConvergenceService.getRiskProject(queryParam).subscribe(
      (getoppo: any) => {
        getoppo.projects.forEach((element: any) => {
          element.color = this.getGMapRandomColor();
        });

        getoppo.objectives.forEach((element: any) => {
          element.color = this.getGMapRandomColor();
        });

        this.portFolioList = getoppo.projects;
        this.impactedOKR = getoppo.objectives;
        this.loading = false;
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
      }
    );

    // queryParam = `risk=${id}`
    // this.RiskConvergenceService.getRiskApplication(queryParam).subscribe((getApplication: any) => {
    //   this.applications = getApplication;
    //   this.loading = false;
    //   this.spinner.hide()
    // }, (error: any) => {
    //   this.spinner.hide();
    // })
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 30%, 75%, 100%)";
  }

  collapse(item: any) {
    if (item.level == "portfolio") {
      this.portFolioList.forEach((element: any) => {
        if (element.id != item.id) element.expanded = false;
      });
    } else if (item.level == "sub_portfolio") {
      let portfolioIndex = this.portFolioList.findIndex(
        (e: any) => e.id == item.parent.id
      );

      if (this.portFolioList[portfolioIndex].children.length > 0) {
        this.portFolioList[portfolioIndex].children.forEach((element: any) => {
          if (element.id != item.id) element.expanded = false;
        });
      }
    } else if (item.level == "program") {
      let portfolioIndex = this.portFolioList.findIndex(
        (e: any) => e.id == item.parent.parent.id
      );
      let subportfolioIndex = this.portFolioList[
        portfolioIndex
      ].children.findIndex((e: any) => e.id == item.parent.id);

      if (
        this.portFolioList[portfolioIndex].children[subportfolioIndex].children
          .length > 0
      ) {
        this.portFolioList[portfolioIndex].children[
          subportfolioIndex
        ].children.forEach((element: any) => {
          if (element.id != item.id) element.expanded = false;
        });
      }
    }
  }

  async getLevel(item: any = null) {
    if (item.children) {
      return;
    }
    let response: any;
    if (item.level == "portfolio") {
      let queryParam = `portfolio=${item.id}&risk=${this.selected}`;

      response = await this.RiskConvergenceService.getRiskProject(
        queryParam
      ).subscribe((res: any) => {
        if (res.sub_portfolio.length > 0) {
          res.sub_portfolio.forEach((element: any) => {
            element.parent = item;
          });
        }
        this.portFolioList[this.portFolioList.indexOf(item)].children =
          res.sub_portfolio;
        return res;
      });
    } else if (item.level == "sub_portfolio") {
      let queryParam = `sub_portfolio=${item.id}&risk=${this.selected}`;

      response = await this.RiskConvergenceService.getRiskProject(
        queryParam
      ).subscribe((res: any) => {
        let portfolioIndex = this.portFolioList.findIndex(
          (e: any) => e.id == item.parent.id
        );
        let subportfolioIndex = this.portFolioList[
          portfolioIndex
        ].children.findIndex((e: any) => e.id == item.id);
        let rec = res.program;
        if (rec.length > 0) {
          rec.forEach((element: any) => {
            element.level = "program";
            element.parent = {
              id: this.portFolioList[portfolioIndex].children[subportfolioIndex]
                .id,
              level: "sub_portfolio",
              parent: {
                id: this.portFolioList[portfolioIndex].id,
                level: "portfolio",
              },
            };
          });
        }

        this.portFolioList[portfolioIndex].children[
          subportfolioIndex
        ].children = rec;
        return rec;
      });
    } else if (item.level == "program") {
      let queryParam = `sub_portfolio=${item.parent.id}&program=${item.id}&risk=${this.selected}`;

      response = await this.RiskConvergenceService.getRiskProject(
        queryParam
      ).subscribe((res: any) => {
        let portfolioIndex = this.portFolioList.findIndex(
          (e: any) => e.id == item.parent.parent.id
        );
        let subportfolioIndex = this.portFolioList[
          portfolioIndex
        ].children.findIndex((e: any) => e.id == item.parent.id);
        let programIndex = this.portFolioList[portfolioIndex].children[
          subportfolioIndex
        ].children.findIndex((e: any) => e.id == item.id);

        if (res.project.length > 0) {
          res.project.forEach((element: any) => {
            element.level = "last";
            element.parent = {
              id: this.portFolioList[portfolioIndex].children[subportfolioIndex]
                .children[programIndex].id,
              level: "program",
              parent: {
                id: this.portFolioList[portfolioIndex].children[
                  subportfolioIndex
                ].id,
                level: "sub_portfolio",
                parent: {
                  id: this.portFolioList[portfolioIndex].id,
                  level: "portfolio",
                },
              },
            };
          });
        }

        this.portFolioList[portfolioIndex].children[subportfolioIndex].children[
          programIndex
        ].children = res.project;
        return res;
      });
    }
  }

  navigateTo(item: any) {
    //  let newitem = {program_name:item.program.name,portfolio_name:item.program.parent.parent.display_name,subporfolio_name:item.program.parent.display_name,manager:item.program.manager}
    //  localStorage.setItem("program",JSON.stringify(newitem))
    this.router.navigate([`view/` + btoa(item.id) + `/overview`]);
  }

  clickOnTab(tab: any) {
    this.accordionItemsLeft = this.accordionItemsLeft.map((obj: any) => {
      if (obj.tab == tab) {
        this.selectedTab = obj;
        return { ...obj, status: "active" };
      } else {
        return { ...obj, status: "inactive" };
      }
    });
  }
  async getPowerBIReportDetails() {
    await this.PowerBiService.getPowerBIReportDetails("risk_insight").subscribe(
      (res: any) => {
        let report = res[0];
        this.loadPowerBIReports(report);
      }
    );
  }

  loadPowerBIReports(report: any) {
    let basicFilter = "";
    this.PowerBiService.getTokenForBI().subscribe((res: any) => {
      this.PowerBiService.getBIEmbedURL(
        res.access_token,
        report?.report_id
      ).subscribe((reportData: any) => {
        this.PowerBiService.getBIEmbedToken(
          res.access_token,
          report?.report_id,
          report?.group
        ).subscribe((tokenData: any) => {
          this.insightModel = this.loadPowerBIReportGraph(
            reportData,
            tokenData,
            basicFilter,
            report?.report_name
          );
        });
      });
    });
  }

  loadPowerBIReportGraph(
    reportData: any,
    tokenData: any,
    basicFilter: any,
    pageName: any
  ) {
    return {
      type: "report",
      id: reportData.id,
      embedUrl: reportData.embedUrl,
      accessToken: tokenData.token,
      tokenType: models.TokenType.Embed,
      pageName: pageName,
      // filters: [basicFilter],
      settings: {
        panes: {
          filters: {
            expanded: false,
            visible: false,
          },
          pageNavigation: {
            visible: false,
          },
        },
        zoomLevel: 0.6,
        background: models.BackgroundType.Transparent,
      },
    };
  }

  openProjectHeatMapModel(modal: any, projectId: any, project_name: any) {
    this.dialog.open(modal, {
      width: "40vw",
      minWidth: "60vw",
      height: "80vh",
    });
    this.projectId = projectId;
    this.project_name = project_name;
    this.getRiskByProjectId(projectId);
  }

  cancel() {
    this.dialog.closeAll();
  }

  getRiskByProjectId(projectId: any) {
    this.spinner.show();
    this.projectService
      .getProjectRiskData(projectId)
      .subscribe((getRisk: any) => {
        getRisk.risk_hierarchy.forEach((domain: any) => {
          domain.level = "domain";
          if (domain.sub_domains.length > 0) {
            domain.children = domain.sub_domains;
            domain.children.forEach((sub: any) => {
              sub.level = "subdomain";
              sub.domain_id = domain.id;
              if (sub.risks.length > 0) {
                sub.children = sub.risks;
                sub.children.forEach((risk: any) => {
                  risk.level = "risk";
                  risk.subdomain_id = sub.id;
                  risk.domain_id = domain.id;

                  if (
                    getRisk.selected_risk_ids.findIndex(
                      (e: any) => e.risk_id == risk.uuid
                    ) > -1
                  ) {
                    let indx = getRisk.selected_risk_ids.findIndex(
                      (e: any) => e.risk_id == risk.uuid
                    );
                    risk.project_risk_id = getRisk.selected_risk_ids[indx].id;
                    risk.selected = true;
                  } else {
                    risk.selected = false;
                  }
                });

                sub.selected = false;

                if (
                  sub.children.filter((e: any) => e.selected === true).length ==
                  sub.children.length
                ) {
                  sub.selected = true;
                  sub.selection_type = "all";
                } else if (
                  sub.children.filter((e: any) => e.selected === true).length >
                  0
                ) {
                  sub.selected = true;
                  sub.selection_type = "intermediate";
                } else {
                  sub.selected = false;
                  sub.selection_type = "none";
                }
              }
            });

            domain.selected = false;

            if (
              domain.children.filter((e: any) => e.selection_type === "all")
                .length == domain.children.length
            ) {
              domain.selected = true;
              domain.selection_type = "all";
            } else if (
              domain.children.filter(
                (e: any) => e.selection_type === "intermediate"
              ).length > 0 ||
              domain.children.filter((e: any) => e.selection_type === "all")
                .length > 0
            ) {
              domain.selected = true;
              domain.selection_type = "intermediate";
            } else {
              domain.selected = false;
              domain.selection_type = "none";
            }
          }
        });
        this.riskArray = getRisk.risk_hierarchy;
        this.riskArrayHeatMap = cloneDeep(this.riskArray);
        this.selectedRisks = getRisk.selected_risk_ids;
        this.spinner.hide();
      });
  }

  clickToDownload() {
    let queryParam = "";
    if (this.filterObj?.portfolio?.array?.length > 0) {
      this.filterObj.portfolio.array.forEach((element: any) => {
        queryParam = queryParam + `&portfolio=${element.id}`;
      });
    } else if (this.filterObj?.subportfolio?.array?.length > 0) {
      this.filterObj.subportfolio.array.forEach((element: any) => {
        queryParam = queryParam + `&subportfolio=${element.id}`;
      });
    } else if (this.filterObj?.program?.array?.length > 0) {
      this.filterObj.program.array.forEach((element: any) => {
        queryParam = queryParam + `&program=${element.id}`;
      });
    } else if (this.filterObj?.project?.array?.length > 0) {
      this.filterObj.project.array.forEach((element: any) => {
        queryParam = queryParam + `&project=${element.id}`;
      });
    } else if (this.filterObj?.domain?.array?.length > 0) {
      this.filterObj.domain.array.forEach((element: any) => {
        queryParam = queryParam + `&domain=${element.id}`;
      });
    } else if (this.filterObj?.subdomain?.array?.length > 0) {
      this.filterObj.subdomain.array.forEach((element: any) => {
        queryParam = queryParam + `&subdomain=${element.id}`;
      });
    } else if (this.filterObj?.riskresponse?.array?.length > 0) {
      this.filterObj.riskresponse.array.forEach((element: any) => {
        queryParam = queryParam + `&risk_response=${element.id}`;
      });
    } else if (this.filterObj?.riskstatus?.array?.length > 0) {
      this.filterObj.riskstatus.array.forEach((element: any) => {
        queryParam = queryParam + `&risk_status=${element.id}`;
      });
    }
    this.spinner.show();
    this.RiskConvergenceService.downloadTrackerList(queryParam).subscribe(
      (res: any) => {
        const blobUrl = window.URL.createObjectURL(res);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = "RiskTracker.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
        this.spinner.hide();
      }
    );
  }

  changeInsightTab() {
    this.showRisk = !this.showRisk;
  }

  openPortfolio(portfolio: any) {
    const dialog = this.dialog.open(PortfolioDialogComponent, {
      width: "auto",
      minWidth: "40vw",
      maxWidth: "50vw",
      height: "auto",
      data: {
        portfolioInfo: portfolio,
      },
    });
    dialog.afterClosed().subscribe((data) => {});
  }

  // filtersFunctions() {
  //   this.getOpportunity();
  //   this.getInitiativeType();
  //   this.getProjectListData()
  // }
  // getRiskHeatMap() {
  //   this.spinner.show();
  //   this.RiskConvergenceService.getRiskHeatMap(this.portFolioId, this.subPortId, this.initativeId, this.project).subscribe((getHeatMap: any) => {
  //     this.spinner.hide()
  //     //if (getHeatMap.risk_list.length > 0) {
  //     this.riskHeatMapData = getHeatMap.risk_list.map((heatMapData: any) => { return { ...heatMapData, y:heatMapData.value } });
  //     this.getAllProjectList();
  //     //}
  //   }, (error) => {
  //     this.spinner.hide();
  //   })

  // }

  // setHeatMapChart() {
  //   let call = this;
  //   let chartOptions1: any = {
  //     title: {
  //       text: ''
  //     },
  //     credits: {
  //       enabled: false
  //     },
  //     series: [{
  //       type: "treemap",
  //       levels: [
  //         {
  //           level: 1,
  //           dataLabels: {
  //             enabled: true,
  //             useHTML: true,
  //             inside: true,
  //             style: {
  //               // fontSize: '1rem',
  //               // fontWeight: "normal",
  //               // textOutline: "0",
  //               // textOverflow: "none",
  //               // padding:'5px',
  //               // width:"20px",
  //             },
  //             // rotation: 0,
  //             // y: 2,
  //             // x: 30,
  //             // formatter:{
  //             //   indentation:"justify"
  //             // }
  //           }
  //         }
  //       ],
  //       events: {
  //         click(e: any) {
  //           call.spinner.show();
  //           call.projectViewAll = false;
  //           call.getProjectList(e.point.id)
  //         }
  //       },
  //       layoutAlgorithm: 'squarified',
  //       data: [...this.riskHeatMapData]
  //     }]
  //   };
  //   chartOptions1.series[0].levels[0].dataLabels.formatter = function () {
  //     return "<p class='pl-1'>" + this.point.name + "</p><p class='pl-1 point-values'><span>" + this.point.risk_value + "</span> " + (this.point.risk_value > 1 ? ' Risks' : ' Risk') + "</p>"
  //   }
  //   this.treeMapChart1 = new Chart(chartOptions1)
  // }

  // setPieChart() {
  //   let call = this;
  //   let pieChartOptions1:any = {
  //     chart: {
  //         type: 'pie',
  //         plotShadow: false,
  //         height:600,
  //         options3d: {
  //             enabled: true,
  //             alpha: 45,
  //             beta: 0
  //         },

  //         renderTo: 'chart',
  //         margin: [0,0,0,0],
  //         spacing: [0,0,0,0],
  //         plotBackgroundColor: null,
  //         plotBackgroundImage: null,
  //         plotBorderWidth: 0,
  //         width: 800,
  //     },
  //     title: {
  //         text: undefined
  //     },
  //     xAxis: {
  //       minPadding: 0,
  //       maxPadding: 0
  //     },
  //     credits: {
  //         enabled: false
  //     },
  //     plotOptions: {
  //       pie: {
  //           allowPointSelect: true,
  //           cursor: 'pointer',
  //           depth: 35,
  //           dataLabels: {
  //               enabled: false,
  //               padding: 0,
  //               verticalAlign: 'middle'
  //           },
  //           showInLegend: true,
  //           size: 500,
  //           center: ['50%','40%'],
  //       }
  //     },
  //     series: [{
  //       name: 'Risks',
  //       colorByPoint: true,
  //       data: this.riskHeatMapData,
  //       groupPadding: 0,
  //       events: {
  //         click(e: any) {
  //           call.spinner.show();
  //           call.projectViewAll = false;
  //           call.getProjectList(e.point.id)
  //         }
  //       },
  //   }],
  //     legend: {
  //         enabled: true,
  //         align: 'center',
  //         verticalAlign: 'bottom',
  //         layout: 'horizontal'
  //     }
  //   }
  //   console.log(this.riskHeatMapData);

  //   this.pieChart = new Chart(pieChartOptions1);
  //   //this.pieChart.addSeries(this.riskHeatMapData, true, true)
  //   // chartOptions1.series[0].levels[0].dataLabels.formatter = function () {
  //   //   return "<p class='pl-1'>" + this.point.name + "</p><p class='pl-1 point-values'><span>" + this.point.value + "</span> " + (this.point.value > 1 ? ' Risks' : ' Risk') + "</p>"
  //   // }
  // }
  // getOpportunity() {
  //   this.ProgramService.getOpportunityFormData('new_opportunity').subscribe((res: any) => {
  //     this.CarouselFilterList = res.records.find((findPortFolio: any) => findPortFolio.field_name === "opportunity_portfolio").field_values;
  //   }, (error: any) => {
  //     throw error;
  //   })
  // }
  // getProjectList(projectId?: any) {
  //   this.spinner.show()
  //   this.allProjectArrow =[]
  //   this.ProjectCount = this.riskHeatMapData.find((findMapData: any) => findMapData.id === projectId) || this.riskHeatMapData.shift();
  //   this.RiskConvergenceService.getHeatMapProjects(projectId).subscribe((getProjectsList: any) => {
  //     this.projectList = getProjectsList.body;
  //     console.log( this.projectList);
  //     this.toggleAllProjects = false;
  //     this.spinner.hide();
  //   }, (error) => {
  //     this.spinner.hide();
  //   })
  // }

  // getAllProjectList() {
  //   this.spinner.show();
  //   this.RiskConvergenceService.getAllHeatMapProjects(this.portFolioId, this.subPortId, this.initativeId, this.project).subscribe((getAllProjects: any) => {
  //     this.allRiskWithProjects = getAllProjects;
  //     this.allRiskWithProjects.forEach((ele:any,i:any)=>{
  //       let count = 0;
  //       (this.allRiskWithProjects[i]['projectList'] || []).forEach((element:any,ind:any)=>{
  //         let val = this.allRiskWithProjects[i]['projectList'][ind]['project_risks'] ? this.allRiskWithProjects[i]['projectList'][ind]['project_risks'] :[];
  //         val?.forEach((domain:any,dIndex:any)=>{
  //           let val1 = val[dIndex]['risk_domain']['risk_sub_domain'] ? val[dIndex]['risk_domain']['risk_sub_domain'] : []
  //             count +=  val1?.reduce( (p:any, c:any) => p = p + c?.risk.length, 0);
  //         })
  //         count +=  val.length;
  //       })
  //       let foundindex= this.riskHeatMapData.findIndex((e:any)=>e.name == ele.name)
  //       if(foundindex>-1)
  //         this.riskHeatMapData[foundindex]['y'] = count;
  //     })
  //     //this.setHeatMapChart();
  //     this.setPieChart();
  //     this.spinner.hide();
  //   }, (error: any) => {
  //     this.spinner.hide();
  //   })

  // }

  // getRandomcolors() {
  //   const colors: any[] = ["#FFA800", "#1A85D6", "#07B797", "#738199", "#B58848", "#706FD3", "#F3819A", "#00F000", "#52E6CB", "#232459", "#EB55D7", "#738199", "#B58848", "#706FD3", "#F3821A", "#00F210", "#72E8CB", "#235359", "#EB32D7"];
  //   let colorIndex = Math.floor(Math.random() * colors.length);
  //   return colors[colorIndex];
  //   // let letters = '0123456789ABCDEF';
  //   // let color = "#";
  //   // for (var i = 0; i < 6; i++) {
  //   //   color += letters[Math.floor(Math.random() * 16)];
  //   // }
  // }

  // getInitiativeType() {
  //   this.ProgramService.getInitiativeData().subscribe((res: any) => {
  //     if (res) {
  //       this.initiativeData = res;
  //     }
  //   });
  // }

  // selectedPortfolioValues(id: any) {
  //   this.portFolioId = id;
  //   this.subPortId = "";
  //   let selectedPortfolio = this.CarouselFilterList.find((e:any)=> e.id === id);
  //   this.subPortfilteredOptions = selectedPortfolio?.sub_portfolio;
  //   this.getRiskHeatMap();
  //    if (this.portFolioId==="") {
  //     this.subPortfilteredOptions = [];
  //    }
  // }

  // selectedSubportfolioValues(id: any) {
  //   this.subPortId = id;
  //   this.getRiskHeatMap();
  // }

  // selectedInitiative(id: any) {
  //   this.initativeId = id;
  //   this.getRiskHeatMap();
  // }

  // clearFilter() {
  //   this.portFolioId = 0;
  //   this.subPortId = 0;
  //   this.initativeId = 0;
  //   this.project = 0;
  //   this.getRiskHeatMap();
  // }

  // getProjectListData() {
  //   this.RiskConvergenceService.projectList().subscribe((projectList: any) => {
  //     if (projectList) {
  //       this.projectListData = projectList;
  //     }
  //   })
  // }
  // selectedProjectId(id: number) {
  //   this.project = id;
  //   this.getRiskHeatMap();
  // }
  // toggleAccordian(index:any){
  //   let val = this.allProjectArrow[index];
  //   this.allProjectArrow = [];
  //   this.allProjectArrow[index] = !val;
  // }
  // toggleSubAccordian(index:any){
  //   let val = this.allSubProjectArrow[index];
  //   this.allSubProjectArrow = [];
  //   this.allSubProjectArrow[index] = !val;
  // }
  // navigateTo(id:any){
  //   this.router.navigate(['/view/'+btoa(id)+'/risklog'])
  // }
}
