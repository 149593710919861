<div class="w-100 bg-lavendar mt-80">
    <app-header></app-header>
</div>
<div class="px-3 py-3">
    <app-bread-crumb></app-bread-crumb>
</div>
<div class="px-3 pb-3">
    <div class="row">
        <div class="col-sm-2">
            <div class="single-left-pane">
                <app-side-menu></app-side-menu>
            </div>
        </div>
        <div class="col-sm-7">
            <div class="single-center-pane">
                <h6>{{staticText?.risk_convergence.risk_register}}</h6>
                <form [formGroup]="createForm">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item" *ngFor="let domains of createForm?.value; let i = index">
                          <h2 class="accordion-header" id="heading{{i}}">
                            <button class="accordion-button collapsed" *ngIf="!domains.edit" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse'+i" aria-expanded="false" [attr.aria-controls]="'collapse' + i">
                                <span><b>{{domains.name || domains.display_name}}</b>
                                </span>
                            </button>
                            <span *ngIf="!domains.edit" class="edit-icon">
                                <i class="fa fa-edit" style="font-size: 13px; margin-bottom: 5px" (click)="edit(i);$event.preventDefault();$event.stopPropagation();"></i>
                                <img src="./assets/images/comment-delete.svg"  (click)="removeDomains(i)" style="width:12px;">
                            </span>
                            <div class="p-3" *ngIf="domains.edit">
                                <mat-form-field appearance="outline" class="w-75">
                                    <mat-label class="label-design"></mat-label>
                                    <input type="text" matInput fullWidth status="basic" placeholder="Domain name"
                                    [(ngModel)]="domains.edit_name" [ngModelOptions]="{standalone: true}">
                                </mat-form-field>
                                <div class="row mt-2">
                                    <div class="col-md-4 ml-auto" style="display: flex; align-items: center; justify-content: end;">
                                        <button (click)="cancel(i)" mat-stroked-button mat-button color="primary" class="mx-1">Cancel
                                        </button>
                                        <button (click)="update(i)" mat-flat-button mat-button class="bg-primary text-white mx-1" color="primary">Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                          </h2>
                          <div id="collapse{{i}}" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading'+i" [attr.data-bs-parent]="'#accordionExample'">
                            <div class="accordion-body">
                                <div class="accordion" id="accordion{{i}}">
                                    <div class="accordion-item" *ngFor="let sub of domains?.subdomains; let j = index">
                                      <h2 class="accordion-header " id="heading{{i}}{{j}}">
                                        <button class="accordion-button collapsed" *ngIf="!sub.edit" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + i + j" aria-expanded="false" [attr.aria-controls]="'collapse' + i + j">
                                            <span><b>{{sub.name || sub.display_name}}</b>
                                            </span>
                                        </button>
                                        <span *ngIf="!sub.edit" class="edit-icon">
                                            <i class="fa fa-edit" style="font-size: 13px; margin-bottom: 5px" (click)="edit(i, j);$event.preventDefault();$event.stopPropagation();"></i>
                                            <img src="./assets/images/comment-delete.svg"  (click)="removeSubdomain(i, j)" style="width:12px;">
                                        </span>
                                        <div class="p-3" *ngIf="sub.edit">
                                            <mat-form-field appearance="outline" class="w-75">
                                                <mat-label class="label-design"></mat-label>
                                                <input type="text" matInput fullWidth status="basic" placeholder="Subdomain name"
                                                [(ngModel)]="sub.edit_name" [ngModelOptions]="{standalone: true}">
                                            </mat-form-field>
                                            
                                            <div class="row mt-2">
                                                <div class="col-md-4 ml-auto" style="display: flex; align-items: center; justify-content: end;">
                                                    <button (click)="cancel(i, j)" mat-stroked-button mat-button color="primary" class="mx-1">Cancel
                                                    </button>
                                                    <button (click)="update(i, j)" mat-flat-button mat-button class="bg-primary text-white mx-1" color="primary">Ok
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        </h2>
                                      <div id="collapse{{i}}{{j}}" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + i + j" [attr.data-bs-parent]="'#accordion' + i">
                                        <div class="accordion-body">
                                            <div *ngFor="let risk of sub?.risks; let k = index" style="padding: 10px; position: relative; background: #f2f2f2; border-radius: 2px; margin-bottom: 10px;">
                                                <div *ngIf="!risk.edit">
                                                    <span style="display: block;
                                                    font-size: 13px; "><b>{{risk.name || risk.display_name || risk.short_description }}</b></span>

                                                    <hr>
                                                    <app-tag [dataObj]="getTagObject(risk.id)" [tagsIds]="risk?.tag_ids"></app-tag>
                                                </div>

                                                <span *ngIf="!risk.edit" class="edit-icon">
                                                    <i class="fa fa-edit" style="font-size: 13px; margin-bottom: 5px" (click)="edit(i, j, k);$event.preventDefault();$event.stopPropagation();"></i>
                                                    <img src="./assets/images/comment-delete.svg"  (click)="removeRisks(i, j, k)" style="width:12px;">
                                                </span>

                                                <div class="p-3" *ngIf="risk.edit">
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-label class="label-design"></mat-label>
                                                        <input type="text" matInput fullWidth status="basic" placeholder="Risk Name"
                                                        [(ngModel)]="risk.edit_name" [ngModelOptions]="{standalone: true}">
                                                    </mat-form-field>

                                                    <div class="row mt-2">
                                                        <div class="col-md-4 ml-auto" style="display: flex; align-items: center; justify-content: end;">
                                                            <button (click)="cancel(i, j, k)" mat-stroked-button mat-button color="primary" class="mx-1">Cancel
                                                            </button>
                                                            <button (click)="update(i, j, k)" mat-flat-button mat-button class="bg-primary text-white mx-1" color="primary">Ok
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <a class="add-btton" (click)="addRisksItems(i, j)">
                                                <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                    class="cursor-pointer fs-5">add
                                                </mat-icon>
                                                Add Risk
                                            </a>
                                        </div>
                                      </div>
                                    </div>
                                    <a class="add-btton" (click)="addSubdomainsItems(i)">
                                        <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                            class="cursor-pointer fs-5">add
                                        </mat-icon>
                                        Add Subdomain
                                    </a>
                                </div>
                            </div>
                          </div>
                        </div>
                        <a class="add-btton" (click)="addDomainItems()">
                            <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                class="cursor-pointer fs-5">add
                            </mat-icon>
                            Add Domain
                        </a>
                    </div>


                    <!-- <div class="custom-table">
                        <table class="table table-bordered">
                            <thead style="position: sticky;top: 0; z-index: 1">
                                <tr>
                                    <th scope="col" class="fixed-header">#</th>
                                    <th scope="col" class="w-100 fixed-header">Domains</th>
                                    <th class="text-center col-2 fixed-header" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let domains of createForm?.value; let i = index">
                                    <td>{{i+1}}</td>
                                    <td class="align-middle w-100">
                                        <div class="row">
                                            <div class="col-md-5">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label class="label-design"></mat-label>
                                                    <input type="text" matInput fullWidth status="basic" placeholder="name"
                                                    [(ngModel)]="domains.name" [ngModelOptions]="{standalone: true}">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-4">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label class="label-design"></mat-label>
                                                    <input type="text" matInput fullWidth status="basic" placeholder="prefix"
                                                    [(ngModel)]="domains.prefix" [ngModelOptions]="{standalone: true}">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-3">
                                                <mat-form-field appearance="outline" class="w-100">
                                                    <mat-label class="label-design"></mat-label>
                                                    <input type="text" matInput fullWidth status="basic" placeholder="impact"
                                                    [(ngModel)]="domains.impact" [ngModelOptions]="{standalone: true}">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <table class="table table-bordered mb-2">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col" class="w-100">Subdomains</th>
                                                    <th class="text-center col-2" scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let sub of domains?.subdomains; let j = index">
                                                    <td>{{j+1}}</td>
                                                    <td class="align-middle w-100">
                                                        <div class="row">
                                                            <div class="col-md-5">
                                                                <mat-form-field appearance="outline" class="w-100">
                                                                    <mat-label class="label-design"></mat-label>
                                                                    <input type="text" matInput fullWidth status="basic" placeholder="name"
                                                                    [(ngModel)]="sub.name" [ngModelOptions]="{standalone: true}">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-md-4">
                                                                <mat-form-field appearance="outline" class="w-100">
                                                                    <mat-label class="label-design"></mat-label>
                                                                    <input type="text" matInput fullWidth status="basic" placeholder="prefix"
                                                                    [(ngModel)]="sub.prefix" [ngModelOptions]="{standalone: true}">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <mat-form-field appearance="outline" class="w-100">
                                                                    <mat-label class="label-design"></mat-label>
                                                                    <input type="text" matInput fullWidth status="basic" placeholder="impact"
                                                                    [(ngModel)]="sub.impact" [ngModelOptions]="{standalone: true}">
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                        <table class="w-100 mb-2">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col" class="w-100">Risks</th>
                                                                    <th class="text-center col-2" scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let risk of sub?.risks; let k = index">
                                                                    <td>{{k+1}}</td>
                                                                    <td>
                                                                        <div class="row">
                                                                            <div class="col-md-5">
                                                                                <mat-form-field appearance="outline" class="w-100">
                                                                                    <mat-label class="label-design"></mat-label>
                                                                                    <input type="text" matInput fullWidth status="basic" placeholder="name"
                                                                                    [(ngModel)]="risk.name" [ngModelOptions]="{standalone: true}">
                                                                                </mat-form-field>
                                                                            </div>
                                                                            <div class="col-md-4">
                                                                                <mat-form-field appearance="outline" class="w-100">
                                                                                    <mat-label class="label-design"></mat-label>
                                                                                    <input type="text" matInput fullWidth status="basic" placeholder="description"
                                                                                    [(ngModel)]="risk.prefix" [ngModelOptions]="{standalone: true}">
                                                                                </mat-form-field>
                                                                            </div>
                                                                            <div class="col-md-3">
                                                                                <mat-form-field appearance="outline" class="w-100">
                                                                                    <mat-label class="label-design"></mat-label>
                                                                                    <input type="text" matInput fullWidth status="basic" placeholder="impact"
                                                                                    [(ngModel)]="risk.impact" [ngModelOptions]="{standalone: true}">
                                                                                </mat-form-field>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div style="display: flex; align-items: center; justify-content: space-around;">
                                                                            <span class="cursor-pointer" (click)="removeRisks(i, j, k)">
                                                                                <span class="cursor-pointer">
                                                                                    <img src="./assets/images/comment-delete.svg"
                                                                                        style="width:16px;">
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td class="w-50">
                                                                        <a class="add-btton"
                                                                            (click)="addRisksItems(i, j)">
                                                                            <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                                                class="cursor-pointer fs-5">add
                                                                            </mat-icon>
                                                                            Add Risks
                                                                        </a>
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td>
                                                        <div style="display: flex; align-items: center; justify-content: space-around;">
                                                            <span class="cursor-pointer" (click)="removeSubdomain(i, j)">
                                                                <span class="cursor-pointer">
                                                                    <img src="./assets/images/comment-delete.svg"
                                                                        style="width:16px;">
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td class="w-50">
                                                        <a class="add-btton"
                                                            (click)="addSubdomainsItems(i)">
                                                            <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                                class="cursor-pointer fs-5">add
                                                            </mat-icon>
                                                            Add Sub domains
                                                        </a>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td>
                                        <div style="display: flex; align-items: center; justify-content: space-around;">
                                            <span class="cursor-pointer" (click)="removeDomains(i)">
                                                <span class="cursor-pointer">
                                                    <img src="./assets/images/comment-delete.svg"
                                                        style="width:16px;">
                                                </span>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td class="w-50">
                                        <a class="add-btton"
                                            (click)="addDomainItems()">
                                            <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                class="cursor-pointer fs-5">add
                                            </mat-icon>
                                            Add Domain
                                        </a>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                </form>
                <div class="row mt-2">
                    <div class="col-md-6">
                    </div>
                    <div class="col-md-6 text-right">
                        <button mat-button mat-flat-button color="primary" class="fs-16 bg-primary text-white" (click)="saveRisk()">
                            Save
                        </button>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-sm-3">
            <div class="single-right-pane">
                <span class="bubble-top" style="background-color: #ED0000;">&nbsp;</span>
                <app-gnews></app-gnews>
                <!-- <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                            style="font-size: 13px">
                            Curated from Web
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample">
                        <div class="accordion-body p-2">
                            <app-loading *ngIf="loading"></app-loading>
                            <app-gnews [display_header]="'false'"></app-gnews>
                        </div>
                    </div>
                </div>    -->
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>