import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { RiskConvergenceService } from 'src/app/shared/services/risk-convergence.service';
import { FilterService } from 'src/app/shared/services/filter.service';

@Component({
  selector: 'app-risk-tracker',
  templateUrl: './risk-tracker.component.html',
  styleUrls: ['./risk-tracker.component.scss']
})
export class RiskTrackerComponent implements OnInit {
  riskTrackerList:any;
  trackerColumn = ['Project','Portfolio','Subportfolio','Program','Risk','Risk Domain','Risk Subdomain','Risk Response','Status'];
  sortRecords: any = 10;
  CurrentPage: any = 0;
  EndValue: any = 10;
  startValue: any = 0;
  firstTimeLoad:boolean = true;
  filterobject:any;
  filterObj: any;
  params:any;
  
  constructor(
    private spinner : NgxSpinnerService,
    private RiskConvergenceService : RiskConvergenceService,
    private filterService: FilterService,
  ) { }

  ngOnInit(): void {
    
    this.filterService.filterSubject.subscribe((res: any) => {
      let awl = false;
      this.filterObj = res;
      Object.keys(this.filterObj).forEach((element: any) => {
        if(this.filterObj[element]?.array?.length > 0){
          awl = true;
          return;
        }
      });
      if(Object.keys(this.filterObj).length > 0){
        awl ? this.getFilteredList() : this.getRiskTrackerList();;
      }else{
        this.getRiskTrackerList();
      }
    })

  }

  getRiskTrackerList(){
    this.spinner.show();
    this.RiskConvergenceService.getRiskTracker(this.startValue, this.EndValue).subscribe((res: any) => {
      this.spinner.hide();
      this.riskTrackerList = res;
    });
    
  }

  async getFilteredList(){
    let queryParam = '';
    if(this.filterObj?.portfolio?.array?.length > 0){
      this.filterObj.portfolio.array.forEach((element: any) => {
        queryParam = queryParam + `&portfolio=${element.id}`
      })
    }else if(this.filterObj?.subportfolio?.array?.length > 0){
      this.filterObj.subportfolio.array.forEach((element: any) => {
        queryParam = queryParam + `&subportfolio=${element.id}`
      })
    }else if(this.filterObj?.program?.array?.length > 0){
      this.filterObj.program.array.forEach((element: any) => {
        queryParam = queryParam + `&program=${element.id}`
      })
    }else if(this.filterObj?.project?.array?.length > 0){
      this.filterObj.project.array.forEach((element: any) => {
        queryParam = queryParam + `&project=${element.id}`
      })
    }else if(this.filterObj?.domain?.array?.length > 0){
      this.filterObj.domain.array.forEach((element: any) => {
        queryParam = queryParam + `&domain=${element.id}`
      })
    }else if(this.filterObj?.subdomain?.array?.length > 0){
      this.filterObj.subdomain.array.forEach((element: any) => {
        queryParam = queryParam + `&subdomain=${element.id}`
      })
    }else if(this.filterObj?.riskresponse?.array?.length > 0){
      this.filterObj.riskresponse.array.forEach((element: any) => {
        queryParam = queryParam + `&risk_response=${element.id}`
      })
    }else if(this.filterObj?.riskstatus?.array?.length > 0){
      this.filterObj.riskstatus.array.forEach((element: any) => {
        queryParam = queryParam + `&risk_status=${element.id}`
      })
    }

    this.spinner.show();
    this.riskTrackerList = {};
    this.RiskConvergenceService.getFilteredRiskTracker(this.startValue, this.EndValue,queryParam).subscribe((res: any) => {
      this.riskTrackerList = res;
      this.spinner.hide()
    })

  }

  getRecords(isFromAttrition?:boolean){
    if (this.CurrentPage > 0) {
      this.startValue = (parseInt(this.CurrentPage) - 1) * (parseInt(this.sortRecords));
    } else {
      this.startValue = 0
    }
    this.EndValue = parseInt(this.startValue) + parseInt(this.sortRecords);
    if(!this.firstTimeLoad){
      this.getFilteredList();
    }
    this.firstTimeLoad = false;
  }

  clickToDownload(){
    let queryParam = '';
    if(this.filterObj?.portfolio?.array?.length > 0){
      this.filterObj.portfolio.array.forEach((element: any) => {
        queryParam = queryParam + `&portfolio=${element.id}`
      })
    }else if(this.filterObj?.subportfolio?.array?.length > 0){
      this.filterObj.subportfolio.array.forEach((element: any) => {
        queryParam = queryParam + `&subportfolio=${element.id}`
      })
    }else if(this.filterObj?.program?.array?.length > 0){
      this.filterObj.program.array.forEach((element: any) => {
        queryParam = queryParam + `&program=${element.id}`
      })
    }else if(this.filterObj?.project?.array?.length > 0){
      this.filterObj.project.array.forEach((element: any) => {
        queryParam = queryParam + `&project=${element.id}`
      })
    }else if(this.filterObj?.domain?.array?.length > 0){
      this.filterObj.domain.array.forEach((element: any) => {
        queryParam = queryParam + `&domain=${element.id}`
      })
    }else if(this.filterObj?.subdomain?.array?.length > 0){
      this.filterObj.subdomain.array.forEach((element: any) => {
        queryParam = queryParam + `&subdomain=${element.id}`
      })
    }else if(this.filterObj?.riskresponse?.array?.length > 0){
      this.filterObj.riskresponse.array.forEach((element: any) => {
        queryParam = queryParam + `&risk_response=${element.id}`
      })
    }else if(this.filterObj?.riskstatus?.array?.length > 0){
      this.filterObj.riskstatus.array.forEach((element: any) => {
        queryParam = queryParam + `&risk_status=${element.id}`
      })
    }
    
    this.spinner.show();
    this.RiskConvergenceService.downloadTrackerList(queryParam).subscribe((res:any)=>{
      const blobUrl = window.URL.createObjectURL(res);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = 'RiskTracker.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
      this.spinner.hide();
     });
   
  }

}
