<div class="w-100 bg-lavendar mt-80">
  <app-header></app-header>
</div>
<div class="px-3 pt-3">
  <app-filter-card [pagetype]="'riskcoverage'"></app-filter-card>
</div>
<div class="px-3 mb-4">
  <div class="py-3">
    <app-bread-crumb></app-bread-crumb>
  </div>
  <div class="row">
    <div class="col-sm-2">
      <div class="single-left-pane">
        <app-side-menu></app-side-menu>
      </div>
    </div>
    <div class="col-sm-7">
      <div class="single-center-pane">
        <div
          class="d-flex mb-3"
          style="align-items: center; justify-content: space-between"
        >
          <div class="d-flex align-items-center gap-1">
            <h5>Delivery Risk</h5>
            <span
              (click)="showPulses()"
              *ngIf="positivePulses > 0"
              class="positive-pulse mb-2"
            ></span>
            <span
              (click)="showPulses()"
              *ngIf="negativePulses > 0"
              class="negative-pulse mb-2"
            ></span>
          </div>
          <div style="display: flex">
            <div
              *ngFor="let item of accordionItemsLeft"
              class="insight-button"
              [ngClass]="{ 'insight-active': item.status === 'active' }"
              (click)="clickOnTab(item.tab)"
            >
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <mat-icon
              *ngIf="selectedTab?.tab == 3"
              aria-hidden="false"
              aria-label="material-icons-filled"
              (click)="clickToDownload()"
              class="cursor-pointer"
              style="
                color: #232459;
                font-size: 28px;
                margin-right: 16px;
                margin-top: 4px;
              "
              >cloud_download
            </mat-icon>
            <button
              mat-button
              [routerLink]="['/riskconvergence/risk-register']"
              class="bg-primary text-white"
              mat-button
              mat-flat-button
              *ngxPermissionsOnly="[
                'delivery.view_riskmeasureofsuccess',
                'delivery.view_riskdomain',
                'delivery.view_risksubdomain',
                'delivery.view_riskcomments'
              ]"
            >
              <mat-icon
                aria-hidden="false"
                aria-label="material-icons-filled "
                class="cursor-pointer fs-5"
                >add
              </mat-icon>
              {{ staticText.risk_convergence.risk_register }}
            </button>
          </div>
        </div>

        <div *ngIf="selectedTab?.tab == 1">
          <div class="table" style="margin-bottom: 0">
            <div
              class="table-row"
              style="position: sticky; top: 0px; z-index: 3; background: white"
            >
              <div
                class="table-cell cursor-pointer domain fw-bold"
                style="
                  max-width: 150px;
                  min-width: 150px;
                  width: 150px;
                  text-align: left;
                  font-size: 13px;
                  background: white;
                  position: sticky;
                  top: 0;
                  left: 0;
                  z-index: 3;
                "
              >
                <div style="background-color: #0bb797; border-radius: 10px">
                  Domain
                </div>
              </div>
              <div class="table-cell table-nest">
                <div
                  class="table-row"
                  style="
                    position: relative;
                    width: 100%;
                    position: sticky;
                    top: 0;
                    left: 150px;
                    z-index: 3;
                  "
                >
                  <div
                    class="table-cell cursor-pointer subdomain"
                    style="
                      max-width: 150px;
                      min-width: 150px;
                      text-align: left;
                      font-size: 13px;
                      background-color: white;
                      position: sticky;
                      top: 0;
                      left: 150px;
                      z-index: 3;
                    "
                  >
                    <div
                      style="
                        background-color: #0bb797;
                        border-radius: 10px;
                        padding: 8px;
                      "
                    >
                      Subdomain
                    </div>
                  </div>
                  <div
                    class="table-cell"
                    style="
                      width: 150px;
                      position: relative;
                      padding: 5px;
                      cursor: pointer;
                      background-color: white;
                      color: white;
                      position: sticky;
                      top: 0;
                      left: 0;
                      z-index: 2;
                    "
                  >
                    <span
                      style="
                        position: sticky;
                        font-size: 13px;
                        text-align: left;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 90%;
                      "
                    >
                      <div
                        style="
                          background-color: #0bb797;
                          border-radius: 10px;
                          padding: 8px;
                        "
                      >
                        Risk
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="riskCenterList"
            style="overflow: scroll; height: calc(100vh - 290px)"
          >
            <div class="container-fluid p-0">
              <div class="table">
                <!-- <div class="table-row" style="position: sticky; top: 0px; z-index: 3; background: white;">
                                <div class="table-cell cursor-pointer domain fw-bold"
                                    style="max-width: 150px;min-width: 150px;text-align: left;font-size:14px; background: white; position: sticky; top: 0;left:0; z-index: 3;">
                                    <div style="background-color: #0bb797; border-radius: 10px;"> Domain </div>

                                </div>
                                <div class="table-cell table-nest">
                                    <div class="table-row" style="position: relative; width: 100%; position: sticky; top: 0; left: 150px; z-index: 3;">
                                        <div class="table-cell cursor-pointer subdomain"
                                            style="max-width: 150px;min-width: 150px;text-align: left;font-size:14px; background-color: white; position: sticky; top: 0;left:150px; z-index: 3;">
                                            <div style="background-color: #0bb797; border-radius: 10px; padding: 8px;"> Subdomain </div>
                                            </div>
                                        <div class="table-cell"
                                            style="width: 150px; position: relative; padding: 5px; cursor: pointer; background-color: white; color: white; position: sticky; top: 0; left: 0; z-index: 2;">
                                            <span style="position: sticky;font-size: 14px;text-align: left;
                                                left: 50%; transform: translate(-50%, -50%); width: 90%; "
                                                > <div style="background-color: #0bb797; border-radius: 10px; padding: 8px;"> Risk </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                <div
                  class="table-row"
                  *ngFor="let domain of riskHeatMapData; let subindex = index"
                >
                  <div
                    class="table-cell cursor-pointer fw-bold"
                    style="
                      max-width: 150px;
                      min-width: 150px;
                      font-size: 14px;
                      position: sticky;
                      left: 0;
                      z-index: 1;
                      height: 0;
                    "
                  >
                    <div
                      class="domain"
                      style="border-radius: 10px; height: 100%"
                    >
                      {{ domain?.display_name }}
                    </div>
                  </div>
                  <div class="table-cell table-nest" *ngIf="domain.sub_domains">
                    <div
                      class="table-row"
                      style="position: relative"
                      *ngFor="
                        let subdomain of domain?.sub_domains;
                        let subsubindex = index
                      "
                    >
                      <div
                        class="table-cell cursor-pointer"
                        id="subdomain_{{ subsubindex }}"
                        style="
                          max-width: 150px;
                          min-width: 150px;
                          font-size: 14px;
                          position: sticky;
                          left: 150px;
                          z-index: 1;
                          height: 0;
                        "
                      >
                        <div
                          class="subdomain"
                          style="
                            border-radius: 10px;
                            padding: 8px;
                            height: 100%;
                          "
                          [matTooltip]="subdomain?.display_name | titlecase"
                        >
                          {{ subdomain?.display_name }}
                        </div>
                      </div>
                      <div
                        class="table-cell"
                        style="
                          max-width: 150px;
                          min-width: 150px;
                          height: 140px;
                          min-height: 140px;
                          position: relative;
                          padding: 5px;
                          cursor: pointer;
                        "
                        *ngFor="
                          let risk of subdomain?.risks;
                          let risksubindex = index
                        "
                      >
                        <span
                          style="
                            position: absolute;
                            font-size: 14px;
                            text-align: center;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 90%;
                            height: 100%;
                            padding: 5px 0;
                          "
                          id="risksubdomain_{{ subsubindex }}_{{
                            risksubindex
                          }}"
                        >
                          <div
                            *ngIf="risk.id"
                            style="
                              border-radius: 10px;
                              padding: 8px;
                              display: flex;
                              padding-bottom: 25px;
                              height: 100%;
                            "
                            [ngStyle]="{
                              backgroundColor:
                                selected == risk.id ? '#86da64' : '#c2bec7'
                            }"
                          >
                            <div
                              [matTooltip]="risk?.display_name | titlecase"
                              *ngIf="risk.id"
                              (click)="showInfo(risk.id)"
                              style="
                                border-radius: 10px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                              "
                            >
                              {{
                                getTrucatedName(
                                  subdomain?.display_name,
                                  risk?.short_description
                                )
                              }}
                            </div>
                            <div
                              class="row"
                              style="position: absolute; bottom: 10px"
                            >
                              <div
                                class="col-sm-6"
                                style="display: flex; align-items: center"
                              >
                                <img
                                  src="assets/images/project-icon.png"
                                  alt="image"
                                  width="30px"
                                  style="
                                    background: #ffffff57;
                                    padding: 1px;
                                    border-radius: 14px;
                                  "
                                />
                                <span
                                  style="margin-left: 5px; font-weight: 900"
                                  >{{ risk.project_count }}</span
                                >
                              </div>
                              <div
                                class="col-sm-6"
                                style="display: flex; align-items: center"
                              >
                                <img
                                  src="assets/images/okr-icon.png"
                                  alt="image"
                                  width="30px"
                                  style="
                                    background: #ffffff57;
                                    padding: 4px;
                                    border-radius: 14px;
                                  "
                                />
                                <span
                                  style="
                                    margin-left: 10px;
                                    margin-left: 5px;
                                    font-weight: 900;
                                  "
                                  >{{ risk.objective_count }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </span>
                        <!-- <span class="bubble-right pt-2 pb-2 ng-star-inserted risk-score-badge"
                                                    id="risksubdomainspan_{{subsubindex}}_{{risksubindex}}"
                                                    *ngIf="risk.risk_score&&risk.risk_score>0"> {{risk.risk_score}}
                                                </span>
                                                <span
                                                    class="bubble-right pt-2 pb-2 ng-star-inserted risk-score-badge-warn"
                                                    id="risksubdomainspan_{{subsubindex}}_{{risksubindex}}"
                                                    *ngIf="risk.isPlannedDatePassed"><span
                                                        class='material-icons-round'>warning</span>
                                                </span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="selectedTab?.tab == 2">
          <div class="cancle-btn-in float-right" (click)="changeInsightTab()">
            <mat-icon *ngIf="showRisk">arrow_forward</mat-icon>
            <mat-icon *ngIf="!showRisk">arrow_back</mat-icon>
          </div>
          <div *ngIf="showRisk">
            <div
              *ngFor="let risk of riskDetails; let i = index"
              class="accordion-item"
            >
              <h2 class="accordion-header" id="heading{{ i }}">
                <button
                  class="accordion-button {{ i === 0 ? '' : 'collapsed' }}"
                  type="button"
                  data-bs-toggle="collapse"
                  attr.data-bs-target="#collapse{{ i }}"
                  aria-expanded="{{ i == 0 ? 'true' : 'false' }}"
                  aria-controls="collapse{{ i }}"
                  style="font-size: 13px; font-weight: 700"
                >
                  {{ risk.risk_name }}
                </button>
              </h2>
              <div
                id="collapse{{ i }}"
                class="accordion-collapse collapse {{ i === 0 ? 'show' : '' }}"
                aria-labelledby="heading{{ i }}"
                data-bs-parent="#accordionPanel"
              >
                <div class="accordion-body p-2">
                  <div class="flex-container-1">
                    <div
                      *ngIf="risk.portfolio && risk.portfolio?.length <= 0"
                      class="trn-no"
                    >
                      No records found.
                    </div>
                    <app-loading
                      style="width: 100%; min-height: 200px"
                      *ngIf="!risk.portfolio"
                    ></app-loading>
                    <div class="flex-item p-1" *ngFor="let x of risk.portfolio">
                      <div
                        class="trn-x"
                        [ngStyle]="{ background: x.color }"
                        (click)="openPortfolio(x)"
                        class="portfolio-card"
                      >
                        <p class="m-0">
                          {{ x.name + " (" + x.projects.length + ")" }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!showRisk">
            <powerbi-report
              [embedConfig]="insightModel"
              [cssClassName]="reportClass"
            ></powerbi-report>
          </div>
        </div>
        <div *ngIf="selectedTab?.tab == 3">
          <app-risk-tracker></app-risk-tracker>
        </div>
      </div>
    </div>
    <div class="col-sm-3">
      <div class="single-right-pane">
        <span class="bubble-top" style="background-color: #ed0000">&nbsp;</span>
        <h6>Insights & Engagement Zone</h6>
        <div *ngIf="selectedTab?.tab == 1">
          <app-loading
            *ngIf="!portFolioList && loading"
            style="height: 90%; display: block"
          ></app-loading>
          <div class="norec" *ngIf="!portFolioList && !loading">
            Select risk to view projects
          </div>
          <div
            class="accordion"
            id="accordionExample"
            *ngIf="portFolioList && !loading"
          >
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  style="font-size: 13px"
                >
                  Mapped Projects
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body p-2">
                  <app-loading *ngIf="!portFolioList"></app-loading>

                  <div
                    *ngIf="portFolioList && portFolioList.length == 0"
                    style="
                      font-size: 0.8em;
                      text-align: center;
                      margin-top: 10px;
                    "
                  >
                    No Project found
                  </div>
                  <div
                    *ngFor="let project of portFolioList"
                    [ngStyle]="{ background: project.color }"
                    class="impacted-okr-style"
                  >
                    <div
                      class="cursor-pointer"
                      (click)="
                        openProjectHeatMapModel(
                          projectHeatMap,
                          project?.id,
                          project?.project_name
                        )
                      "
                    >
                      {{ project?.project_name }}
                    </div>
                  </div>
                  <!-- <div class="content-tree-holder mt-2" *ngIf="portFolioList">
                                    <app-tree-listing [items]="portFolioList" [tree]="project" [rightpane]="true"
                                        [context]="context"></app-tree-listing>
                                </div> -->
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                  style="font-size: 13px"
                >
                  Impacted OKR
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse p-2"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body p-0">
                  <app-loading *ngIf="!impactedOKR"></app-loading>
                </div>
                <div
                  *ngIf="impactedOKR && impactedOKR.length == 0"
                  style="font-size: 0.8em; text-align: center; margin-top: 10px"
                >
                  No OKR found
                </div>
                <div
                  *ngFor="let okr of impactedOKR"
                  [ngStyle]="{ background: okr.color }"
                  class="impacted-okr-style"
                >
                  <div class="cursor-pointer">{{ okr?.title }}</div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="true"
                  aria-controls="collapseFour"
                  style="font-size: 13px"
                >
                  Curated from Web
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse show"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body p-2">
                  <app-loading *ngIf="loading"></app-loading>
                  <app-gnews [display_header]="'false'"></app-gnews>
                </div>
              </div>
            </div>

            <!-- <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"
                                style="font-size: 13px">
                                Mapped Applications
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body p-1 mt-1">
                                <div *ngIf="applications && applications.length == 0" style="font-size: 0.8em; text-align: center; margin-top: 10px;">
                                    No Applications found
                                </div>
                                <div *ngFor="let app of applications" class="applc">{{app.name}}</div>
                            </div>
                        </div>
                    </div> -->
          </div>
        </div>
        <div *ngIf="selectedTab?.tab == 2 || selectedTab?.tab == 3">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="true"
                  aria-controls="collapseFour"
                  style="font-size: 13px"
                >
                  Curated from Web
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse show"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body p-2">
                  <app-loading *ngIf="loading"></app-loading>
                  <app-gnews [display_header]="'false'"></app-gnews>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

<ng-template #projectHeatMap>
  <div class="modal-dialog m-0" style="max-width: 100%">
    <div class="modal-content" style="border: none">
      <app-modal-header-common [title]="project_name">
      </app-modal-header-common>

      <div
        class="modal-body p-0 p-3 incident-tracker"
        style="overflow-y: auto; height: 450px"
      >
        <app-risklog-heatmap
          [riskArrayHeatMap]="riskArrayHeatMap"
          [projectId]="projectId"
        ></app-risklog-heatmap>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="d-flex justify-content-between">
            <button (click)="cancel()" mat-flat-button color="secondary">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
